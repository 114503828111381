import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types'

//Sections
import Intro from '../indexsections/Intro';
import Projects from '../indexsections/Projects';
import TeamGrid from '../indexsections/TeamGrid';
import MailForm from '../common/MailForm';

const Index = ({AppName, AppUrl, setCurrentPage}) => {

    useEffect(() => {
		setCurrentPage('index');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{AppName}</title>
                <meta name="description" content={AppName} />
                <meta name="keywords" content={AppName} />
                <meta property="og:title" content={AppName} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <div className="page-text">
				<Intro />
                <Projects />
                <TeamGrid />
                <MailForm />
            </div>
        </>
    )
}

Index.propTypes = {
    AppName: PropTypes.string,
    AppUrl: PropTypes.string,
    LinkTw: PropTypes.string,
    LinkDs: PropTypes.string,
    LinkMd: PropTypes.string
}

export default Index
