import './Projects.css';

import imgProject1 from '../../assets/images/project-1.jpg';
import imgProject1_2x from '../../assets/images/project-1-2x.jpg';
import imgProject1Web from '../../assets/images/project-1.webp';
import imgProject1_2xWeb from '../../assets/images/project-1-2x.webp';

import imgProject2 from '../../assets/images/project-2.jpg';
import imgProject2_2x from '../../assets/images/project-2-2x.jpg';
import imgProject2Web from '../../assets/images/project-2.webp';
import imgProject2_2xWeb from '../../assets/images/project-2-2x.webp';

import imgProject3 from '../../assets/images/project-3.jpg';
import imgProject3_2x from '../../assets/images/project-3-2x.jpg';
import imgProject3Web from '../../assets/images/project-3.webp';
import imgProject3_2xWeb from '../../assets/images/project-3-2x.webp';

const Projects = () => {
    var srcProject1 = imgProject1 + ' 1x, ' + imgProject1_2x + ' 2x';
    var srcProject1Web = imgProject1Web + ' 1x, ' + imgProject1_2xWeb + ' 2x';

    var srcProject2 = imgProject2 + ' 1x, ' + imgProject2_2x + ' 2x';
    var srcProject2Web = imgProject2Web + ' 1x, ' + imgProject2_2xWeb + ' 2x';

    var srcProject3 = imgProject3 + ' 1x, ' + imgProject3_2x + ' 2x';
    var srcProject3Web = imgProject3Web + ' 1x, ' + imgProject3_2xWeb + ' 2x';

    return (
        <div className="landing-container" id="index-projects">
            <h2 className='rutitle'>Projects</h2>
            <div className='projects-list'>
                <a href="https://wannabesmusic.club" title="Wannabes Music Club" target="_blank" rel="noreferrer nofollow noopener">
                    <span>Wannabes Music Club</span>
                    <picture>
                        <source srcSet={srcProject1Web} type="image/webp" />
                        <source srcSet={srcProject1} type="image/jpeg" /> 
                        <img src={imgProject1} alt="Wannabes Music Club" title="Wannabes Music Club" width="300" height="190" />
                    </picture> 
                </a>

                <a href="https://vinyl.wannabesmusic.club" title="Vinyl" target="_blank" rel="noreferrer nofollow noopener">
                    <span>Vinyl</span>
                    <picture>
                        <source srcSet={srcProject2Web} type="image/webp" />
                        <source srcSet={srcProject2} type="image/jpeg" /> 
                        <img src={imgProject2} alt="Vinyl" title="Vinyl" width="300" height="190" />
                    </picture>
                </a>

                <a href="https://idntts.com" title="IDNTTS" target="_blank" rel="noreferrer nofollow noopener">
                    <span>IDNTTS</span>
                    <picture>
                        <source srcSet={srcProject3Web} type="image/webp" />
                        <source srcSet={srcProject3} type="image/jpeg" /> 
                        <img src={imgProject3} alt="IDNTTS" title="IDNTTS" width="300" height="190" />
                    </picture>
                </a>
            </div>
        </div>
    )
}

export default Projects
