import './team.css';

/*
import imgTeam1 from '../../assets/images/team_1.png';
import imgTeam2 from '../../assets/images/team_2.png';
import imgTeam3 from '../../assets/images/team_3.png';
import imgTeam4 from '../../assets/images/team_4.png';
import imgTeam5 from '../../assets/images/team_5.png';
import imgTeam6 from '../../assets/images/team_6.png';
*/

import imgTeam1 from '../../assets/images/team_1p.jpg';
import imgTeam2 from '../../assets/images/team_2p.jpg';
import imgTeam3 from '../../assets/images/team_3p.jpg';
import imgTeam4 from '../../assets/images/team_4p.jpg';
import imgTeam5 from '../../assets/images/team_5p.jpg';
import imgTeam6 from '../../assets/images/team_6p.jpg';
import imgTeam7 from '../../assets/images/team_7p.jpg';

const TeamGrid = () => {
    return (
        <div className="landing-container" id="index-team-grid">
            <h2 className='rutitle'>Team</h2>
            <div className='teambox' id='teambox'>
                <a target='_blank' rel='noreferrer' href="https://twitter.com/filmakarov" className='member'>
                    <img src={imgTeam1} alt="Fil" title="Fil" />
                    <div className='name'> Fil</div>
                    <div className='role'> Generative script<br/>Smart contracts</div>
                </a>

                <a target='_blank' rel='noreferrer' href="https://twitter.com/UginNFT" className='member'>
                    <img src={imgTeam2} alt="Eugene" title="Eugene" />
                    <div className='name'> Eugene</div>
                    <div className='role'>Art and style</div>
                </a>

                <a target='_blank' rel='noreferrer' href="https://twitter.com/MikeSpikeWhite" className='member'>
                    <img src={imgTeam3} alt="Max" title="Max" />
                    <div className='name'> Max</div>
                    <div className='role'>Fullstack dev</div>
                </a>

                <div className='member'>
                    <img src={imgTeam7} alt="Ilya" title="Ilya" />
                    <div className='name'> Ilya</div>
                    <div className='role'>Head of Business Development</div>
                </div>    

                <a target='_blank' rel='noreferrer' href="https://twitter.com/epi_is_here" className='member'>
                    <img src={imgTeam4} alt="Daria" title="Daria" />
                    <div className='name'> Daria</div>
                    <div className='role'>Artist relations<br/>Project management</div>
                </a>       

                <a target='_blank' rel='noreferrer' href="https://twitter.com/g_wbmc" className='member'>
                    <img src={imgTeam5} alt="George" title="George" />
                    <div className='name'> George</div>
                    <div className='role'>Creative producer<br />Community management</div>
                </a>

                <div className='member'>
                    <img src={imgTeam6} alt="Marina" title="Marina" />
                    <div className='name'> Marina</div>
                    <div className='role'>Marketing & partnership</div>
                </div>
            </div>
        </div>
    )
}

export default TeamGrid
