import { useState, useEffect } from 'react';

const Intro = () => {
    const [introVideoStarted, setIntroVideoStarted] = useState(false);

    return (
        <div className="landing-container" id="index-intro">
            <h2 className='rutitle'>Hello, this is GoNifty Lab - a team of NFT and crypto enthusiasts</h2>

            <p>We believe in the positive development of technology and we know how to use innovative tools for the benefit of those who work with them. We create NFT collections, build communities and organize events in the metaverse.</p>

            <p>We especially love working with musicians, but are open to any creative ideas.</p>

            <p>GoNifty Lab is a team of professionals: creators, web developers and smart contract developers, NFT marketing specialists and analysts, as well as musicians and music managers who speak the same language with you.</p>

            <h2 className='rutitle'>We create turnkey NFT collections</h2>
            <p>You can trust us with: 
                <ul style={{listStyleType: "none"}}>
                    <li>— development of concepts and mechanics of your NFT collection</li>
                    <li>– visual and technical implementation of the collection</li>
                    <li>— development of custom smart contracts</li>
                    <li>— creation of a platform for the implementation and promotion of the NFT collection</li>
                    <li>— development of strategies to attract a new audience ready to purchase your NFT product</li>
                    <li>– creation and development of a community around the collection</li>
                    <li>— consultation, support and education at all stages of development and implementation</li>
                </ul>
            </p>

            <p>Our main principle is to find the unique value of everyone we work with.</p> 
            <p>We can guarantee that your collection will have meaning and history, will function on interesting cryptomechanics and be liked by you, us and your audience.</p>
            
            {/*
            <h2 className='rutitle'>Привет, это GoNifty Lab - команда NFT- и крипто-энтузиастов</h2>

            <p>Мы верим в позитивное развитие технологий и знаем, как использовать инновационные инструменты на пользу тем, кто с ними работает. Мы создаем NFT-коллекции, строим комьюнити и устраиваем мероприятия в метавселенных.</p>

            <p>Мы особенно любим работать с музыкантами, но открыты к любым креативным идеям.</p>

            <p>GoNifty Lab - это команда профессионалов: креаторов, веб-разработчиков и разработчиков смарт-контрактов, маркетинговых NFT-специалистов и аналитиков, а также музыкантов и музыкальных менеджеров, которые говорят с вами на одном языке.</p>

            <h2 className='rutitle'>Мы создаем NFT-коллекции под ключ</h2>
            <p>Вы можете доверить нам:
                <ul style={{listStyleType: "none"}}>
                    <li>—  разработку концепций и механик вашей NFT-коллекции</li>
                    <li>—  визуальное и техническое воплощение коллекции</li>
                    <li>—  разработку кастомных смарт-контрактов</li>
                    <li>—  создание платформы для реализации и продвижения NFT-коллекции</li>
                    <li>—  разработку стратегий по привлечению новой аудитории, готовой приобрести ваш NFT-продукт</li>
                    <li>— создание и развитие комьюнити вокруг коллекции</li>
                    <li>— консультацию, поддержку и просвещение на всех этапах разработки и реализации</li>
                </ul>
            </p>

            <p>Наш главный принцип - поиск уникальной ценности каждого, с кем мы работаем.</p> 
            <p>Мы можем гарантировать, что ваша коллекция будет иметь смысл и историю, функционировать на интересных криптомеханиках и нравиться вам, нам и вашей аудитории.</p>
            */}
        </div>
    )
}

export default Intro
