import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import Index from './components/pages/Index';
import NoMatch from './components/pages/NoMatch';

// Components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
//import "./reset.scss";
//import "./global.scss";

const AppName = 'GoNifty Lab';
const AppUrl = 'https://goniftylab.com';

const LinkTw = 'https://twitter.com/goniftylab';
const LinkDs = 'https://discord.gg/9WtefR6z3e';
const LinkMd = '';

function App(){
  const [currentPage, setCurrentPage] = useState('index');

  return (
    <>
      <Header AppName={AppName} LinkTw={LinkTw} LinkDs={LinkDs}/>
      <main>
          <div id="content">
              <Switch>
                  <Route path='/' exact ><Index AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkTw={LinkTw} LinkDs={LinkDs} LinkMd={LinkMd} /></Route>
                  <Route path="*"><NoMatch AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} /></Route>
              </Switch>
          </div>
      </main>
      <Footer AppName={AppName} LinkTw={LinkTw} LinkDs={LinkDs} LinkMd={LinkMd} />
    </>
  );
};

export default App;