import {useState} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import './MailForm.css';

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!firstName){
            setErrorMsg("Enter your name");
        } else {
            if(!email){
                setErrorMsg("Enter your email");
            } else {  
                if(email.indexOf("@") > -1){
                    setErrorMsg("");

                    email &&
                    firstName &&
                    email.indexOf("@") > -1 &&
                    onValidated({
                        MERGE0: email,
                        MERGE1: firstName,
                    });
                } else {
                    setErrorMsg("Invalid email");
                }
            }
        }
    }

    if(status === "success"){
        return (
            <div
                className="mc__alert mc__alert--success"
                dangerouslySetInnerHTML={{ __html: message }}
            />
        );
    } else {
        return (
            <form className="landing-about-2-email-form" onSubmit={(e) => handleSubmit(e)} id="mail-form">
            <div className="landing-about-2-label-2">Subscribe to our news</div>
            <div className="email-form-validation-error-msg">{errorMsg}</div>
            {status === "sending" && (
            <div className="mc__alert mc__alert--sending">
                sending...
            </div>
            )}

            {status === "error" && (
            <div 
                className="mc__alert mc__alert--error"
                dangerouslySetInnerHTML={{ __html: message }}
            />
            )}
            
            <input type="text" name="name" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Name" className="subscribeInput" />
            <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" className="subscribeInput" />
            <input type="submit" value="Subscribe" className="subscribeButton" />
        </form>
        );
    }
};

const MailForm = () => {
    const postUrl = `https://goniftylab.us14.list-manage.com/subscribe/post?u=e96088da94e13caf7592c082e&amp;id=77f62e5e8e`;

    return (
        <div className="landing-about-2" id="mail-form">
            <div className="landing-about-2-label">News</div>
            <MailchimpSubscribe 
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}

export default MailForm